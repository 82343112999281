import React from 'react';
import Footer from '../common/Footer';
import Header from '../common/Header';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import hmleftarrow from '../../images/hmleft_arrow.svg';
import hmrightarrow from '../../images/hmright_arrow.svg';
import hmleararrow from '../../images/hm_leararrow.svg';
import debounce from 'lodash.debounce'; // You need to install lodash.debounce
import { useNavigate, useLocation } from "react-router-dom";
import Modal from 'react-modal';
import NiceSelect from '../../NiceSelect/NiceSelect';
import successcheck2 from '../../images/success-check2.svg';
import BrandLogo from '../common/BrandLogo';
import { Helmet } from 'react-helmet-async';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
};


const RestaurantInventoryManagement = () => {
  const location = useLocation();
  const resetForms = () => {
    setFormData({
      name: "",
      mobileNumber: "",
      pincode: "",
      incomeSource: "",
      buss_age: "",
      buss_turnover: "",
      email: "",
    });
  };

  const [firstFormId, setFirstFormId] = useState("");
  const [hasError, setHasError] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    countryCode: "+91",
    mobileNumber: "",
    pincode: "",
    incomeSource: "",
    buss_age: "",
    buss_turnover: "",
    email: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    mobileNumber: "",
    pincode: "",
    incomeSource: "",
    buss_age: "",
    buss_turnover: "",
    email: "",
  });
  const inputName = useRef(null);
  const inputNumber = useRef(null);
  const inputEmail = useRef(null);
  //const countryCodes = ["+91", "+1", "+44", "+61", "+81", "+86"];
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);

  const openModal1 = () => {
    setModalIsOpen1(true);
  };

  const openModal2 = () => {
    setModalIsOpen2(true);
    setModalIsOpen1(false);
  };

  const closeModal1 = () => {
    setModalIsOpen1(false);
  };

  const closeModal2 = () => {
    setModalIsOpen2(false);
  };

  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const domains = ["gmail.com", "yahoo.com", "hotmail.com"];

  const validateSecondForm = () => {
    const errors = {};
    let formIsValid = true;
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberRegex = /^\d+$/;

    if (!formData.name || !formData.name.trim()) {
      formIsValid = false;
      errors.name = "Name is required";
    } else if (formData.name.trim().length < 3) {
      formIsValid = false;
      errors.name = "Name should have at least 3 characters";
    } else if (formData.name.trim().length > 30) {
      formIsValid = false;
      errors.name = "Name should have at max 30 characters";
    } else if (!nameRegex.test(formData.name.trim())) {
      formIsValid = false;
      errors.name = "Name should contain only letters and spaces";
    }

    if (!formData.email || !formData.email.trim()) {
      formIsValid = false;
      errors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      formIsValid = false;
      errors.email = "Invalid email address";
    }

    if (!formData.mobileNumber || !formData.mobileNumber.trim()) {
      formIsValid = false;
      errors.mobileNumber = "Mobile Number is required";
    } else if (!numberRegex.test(formData.mobileNumber)) {
      formIsValid = false;
      errors.mobileNumber = "Invalid mobile number";
    } else if (formData.mobileNumber.trim().length !== 10) {
      formIsValid = false;
      errors.mobileNumber = "Mobile number must be exactly 10 digits";
    } else {
      // Ensure mobile number starts with 6, 7, 8, or 9
      const firstDigit = formData.mobileNumber.trim().charAt(0);
      if (!["6", "7", "8", "9"].includes(firstDigit)) {
        formIsValid = false;
        errors.mobileNumber = "Mobile number must start with 6, 7, 8, or 9";
      }
    }

    setFormErrors(errors);
    setHasError(!formIsValid);

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "name") {
      if (value === "" || /^[^\s]/.test(value)) {
        newValue = value;
      } else {
        newValue = value.trimStart();
      }
    } else if (name === "mobileNumber") {
      newValue = value.replace(/\D/g, "");

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    } else if (name === "email") {
      if (value.includes("@")) {
        const [localPart, domainPart] = value.split("@");
        setEmailSuggestions(
          domains
            .filter((domain) => domain.startsWith(domainPart))
            .map((domain) => `${localPart}@${domain}`)
        );
      } else {
        setEmailSuggestions([]);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateSecondForm();
  };

  const formClassName = hasError ? "form-error form-group" : "form-group";
  const validateAndOpenModal2 = async () => {
    if (await validateSecondForm()) {
      const apiUrl = process.env.REACT_APP_FORM_ONE;
      const combinedData = {
        name: formData.name,
        phone: formData.mobileNumber,
        email: formData.email,
        source: window.location.href,
      };
      localStorage.setItem("mobile", formData.mobileNumber);
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json; charset=utf-8",
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        setFirstFormId(data.id);
        openModal2();
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // const saveDetails = () => {
  //   closeModal2();
  // };
  const navigate = useNavigate();
  const validateForm = () => {
    const errors = {};
    let formIsValid = true;

    if (!formData.incomeSource || formData.incomeSource === "0") {
      formIsValid = false;
      errors.incomeSource = "Please select a business category";
    }

    if (!formData.role || formData.role === "0") {
      formIsValid = false;
      errors.role = "Please select your role";
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const saveDetails = async () => {
    const isValid = validateForm();

    if (isValid) {
      const apiUrl = process.env.REACT_APP_FORM_ONE + "/" + firstFormId;
      const combinedData = {
        businessCategory: formData.incomeSource,
        role: formData.role,
      };
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        resetForms();
        closeModal2();
        navigate("/thankyou");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const sectionRef = useRef(null);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const sectionRefs = useRef(null);
  const tabsRef = useRef(null);
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [areTabsVisible, setAreTabsVisible] = useState(false);

  useEffect(() => {
    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.target === sectionRefs.current) {
          setIsSectionVisible(entry.isIntersecting);
        }
        if (entry.target === tabsRef.current) {
          setAreTabsVisible(entry.isIntersecting);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1, // Adjust this as needed
    });

    if (sectionRefs.current) {
      observer.observe(sectionRefs.current);
    }

    if (tabsRef.current) {
      observer.observe(tabsRef.current);
    }

    return () => {
      if (sectionRefs.current) {
        observer.unobserve(sectionRefs.current);
      }
      if (tabsRef.current) {
        observer.unobserve(tabsRef.current);
      }
    };
  }, []);

  const [activeTab, setActiveTab] = useState("flamingo");

  const handlePrevTab = () => {
    switch (activeTab) {
      case "cuckoo":
        setActiveTab("flamingo");
        break;
      case "ostrich":
        setActiveTab("cuckoo");
        break;
      case "tropicbird":
        setActiveTab("ostrich");
        break;
      case "tropicbird-1":
        setActiveTab("tropicbird");
        break;
      default:
        break;
    }
  };

  const handleNextTab = () => {
    switch (activeTab) {
      case "flamingo":
        setActiveTab("cuckoo");
        break;
      case "cuckoo":
        setActiveTab("ostrich");
        break;
      case "ostrich":
        setActiveTab("tropicbird");
        break;
      case "tropicbird":
        setActiveTab("tropicbird-1");
        break;
      default:
        break;
    }
  };

  const handleNavItemClick = (tab) => {
    setActiveTab(tab);
  };
  // const carouselRef = useRef(null);
  // const [currentSlide, setCurrentSlide] = useState(0);
  // const [totalSlides, setTotalSlides] = useState(0);
  // const isDesktop = () => {
  //   return !('ontouchstart' in window || navigator.maxTouchPoints > 0);
  // };

  // const handleScrolls = (e) => {
  //   if (!isDesktop()) return;

  //   if (sectionRefs.current) {
  //     const targetPosition = sectionRefs.current.getBoundingClientRect().top;
  //     const scrollPosition = window.scrollY + window.innerHeight;

  //     if (scrollPosition >= targetPosition + window.scrollY) {
  //       const owl = carouselRef.current;
  //       if (currentSlide < totalSlides - 1) {
  //         window.scrollTo(
  //           0,
  //           targetPosition + window.scrollY - window.innerHeight
  //         );

  //         if (owl && owl.next) {
  //           owl.next();
  //         }
  //         setCurrentSlide(currentSlide + 1); // Update current slide manually
  //         e.preventDefault();
  //       } else {
  //         window.removeEventListener('wheel', handleScrolls);
  //       }
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (isDesktop()) {
  //     window.addEventListener('wheel', handleScrolls, { passive: false });
  //   }

  //   return () => {
  //     if (isDesktop()) {
  //       window.removeEventListener('wheel', handleScrolls);
  //     }
  //   };
  // }, [currentSlide, totalSlides]);

  // const options = {
  //   items: 1,
  //   nav: false,
  //   dots: true,
  //   loop: false,
  //   margin: 8,
  //   autoplay: false,
  //   onInitialized: (event) => {
  //     setTotalSlides(event.item.count);
  //   },
  //   onChange: (event) => {
  //     // setCurrentSlide(event.item.index);
  //   },
  // };

  const carouselRef = useRef(null);
  const sectionRefsss = useRef(null); // Reference to the section you want to observe
  const headerRef = useRef(null); // Reference to the header component
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [isCarouselActive, setIsCarouselActive] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null); // 'down' or 'up'

  const isDesktop = () => {
    return !("ontouchstart" in window || navigator.maxTouchPoints > 0);
  };

  const handleScrolls = (e) => {
    if (!isDesktop() || !isCarouselActive || scrollDirection !== "down") return;

    if (sectionRef.current && carouselRef.current) {
      const sectionTop = sectionRef.current.getBoundingClientRect().top;
      const headerBottom = headerRef.current.getBoundingClientRect().bottom;
      const scrollPosition = window.scrollY + window.innerHeight;

      // Check if the section is touching the header
      if (
        scrollPosition >= sectionTop + window.scrollY &&
        scrollPosition >= headerBottom
      ) {
        const owl = carouselRef.current;
        if (currentSlide < totalSlides - 1) {
          // Scroll the carousel
          if (owl && owl.next) {
            owl.next();
          }
          setCurrentSlide(currentSlide + 1); // Update current slide manually
          e.preventDefault();
        } else {
          window.removeEventListener("wheel", handleScrolls);
        }
      }
    }
  };

  const handleScroll = () => {
    const newScrollDirection =
      window.scrollY > (scrollDirection === "down" ? 0 : window.scrollY)
        ? "down"
        : "up";
    setScrollDirection(newScrollDirection);
  };

  useEffect(() => {
    if (isDesktop()) {
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("wheel", handleScrolls, { passive: false });
    }

    return () => {
      if (isDesktop()) {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("wheel", handleScrolls);
      }
    };
  }, [currentSlide, totalSlides, isCarouselActive, scrollDirection]);

  const options = {
    items: 1,
    nav: false,
    dots: true,
    loop: false,
    margin: 8,
    autoplay: false,
    onInitialized: (event) => {
      setTotalSlides(event.item.count);
    },
    onChange: (event) => {
      setCurrentSlide(event.item.index);
    },
  };

  useEffect(() => {
    const checkIfSectionInView = () => {
      console.log("Checking if section is in view...");
      if (sectionRefsss.current && headerRef.current) {
        const sectionRect = sectionRefsss.current.getBoundingClientRect();
        const headerRect = headerRef.current.getBoundingClientRect();
        const scrollTop = window.scrollY;
        const viewportHeight = window.innerHeight;

        // Compute absolute positions
        const sectionTop = sectionRect.top + scrollTop;
        const sectionBottom = sectionRect.bottom + scrollTop;
        const headerTop = headerRect.top + scrollTop;
        const headerBottom = headerRect.bottom + scrollTop;

        // Log values for debugging
        console.log("Section Top (absolute):", sectionTop);
        console.log("Section Bottom (absolute):", sectionBottom);
        console.log("Header Top (absolute):", headerTop);
        console.log("Header Bottom (absolute):", headerBottom);

        // Check if both section and header are fully within the viewport
        const isSectionFullyInView =
          sectionTop >= scrollTop &&
          sectionBottom <= scrollTop + viewportHeight;
        const isHeaderFullyInView =
          headerTop >= scrollTop && headerBottom <= scrollTop + viewportHeight;

        console.log("Is Section Fully in View:", isSectionFullyInView);
        console.log("Is Header Fully in View:", isHeaderFullyInView);

        if (isSectionFullyInView && isHeaderFullyInView) {
          alert("hi");
          setIsCarouselActive(true);
        } else {
          setIsCarouselActive(false);
        }
      } else {
        console.log("Element references are null.");
      }
    };

    window.addEventListener("scroll", checkIfSectionInView);
    return () => {
      window.removeEventListener("scroll", checkIfSectionInView);
    };
  }, []);

  const [footerMobile, setFooterMobile] = useState("");
  const footerMobileRef = useRef(null);
  const [hasFooterMobileError, setHasFooterMobileError] = useState(false);
  const [hasFooterMobileErrorText, setHasFooterMobileErrorText] = useState("");
  const handleChangeFooterForm = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "mobileNumberfooter") {
      console.log(newValue.length);
      newValue = value.replace(/\D/g, "");

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    }
    setFooterMobile(newValue);
  };
  const validateFooterMobileForm = () => {
    let formIsValid = true;
    const numberRegex = /^\d+$/;
    if (!footerMobile || !footerMobile.trim()) {
      formIsValid = false;
      setHasFooterMobileErrorText("Mobile Number is required");
    } else if (!numberRegex.test(footerMobile)) {
      formIsValid = false;
      setHasFooterMobileErrorText("Invalid mobile number");
    } else if (footerMobile.trim().length !== 10) {
      formIsValid = false;
      setHasFooterMobileErrorText("Mobile number must be exactly 10 digits");
    } else {
      const firstDigit = footerMobile.trim().charAt(0);
      if (!["6", "7", "8", "9"].includes(firstDigit)) {
        formIsValid = false;
        setHasFooterMobileErrorText(
          "Mobile number must start with 6, 7, 8, or 9"
        );
      }
    }
    setHasFooterMobileError(!formIsValid);

    return formIsValid;
  };
  return (
    <div>
            <Helmet>
        <title>
        Restaurant Inventory Management Software | Rista Restaurant POS
        </title>
        <meta
          name="description"
          content="Best Restaurant POS software to manage your kitchen inventory, raw material stock, recipe management, central kitchen management & more."
        />
        <meta
          name="keywords"
          content="Restaurant POS Software, Restaurant Billing Software, Restaurant Inventory Management"
        />
        <meta name="author" content="Rista Apps" />
        <meta
          property="og:description"
          content="Best Restaurant POS software to manage your kitchen inventory, raw material stock, recipe management, central kitchen management & more."
        />
        <meta
          property="og:image"
          content={require("../../images/rista_logo3.svg")}
        />
      </Helmet>

      <Header ref={headerRef} />

      <section className="banner banner_home banner_noimage banner_direct_ordering set_banner set_inventory_banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <div className="banner-content">
                <span className="banner-subheading home_banner_subheading">
                  Back-of-the-house
                </span>
                <h1 className="banner-heading">
                  Inventory <br className="invent_mobile" />
                  management for <span>maximum profit</span>
                </h1>
                <div className="banner-content hm_banner_desk">
                  <h2 className="banner-para">
                    Manage sales, purchasing, and food production effortlessly.
                    Maximize your ingredients and plan ahead to minimize waste.
                  </h2>
                  <div className="ban-bt">
                    <NavLink className="hm_fillbtn" onClick={openModal1}>
                      Get a Demo
                    </NavLink>
                    {/* <a class="ban-second-cta" href="#">Know How/Learn More</a> */}
                  </div>
                  <div className="banner-mobile">
                    <div className="ban-last">
                      <img
                        className="ban-last-img"
                        src={require("../../images/star.png")}
                      ></img>{" "}
                      Top 20 Technology Startups <span>LinkedIn, 2023</span>
                    </div>
                    <div className="ban-last ban-last-1">
                      <img
                        className="ban-last-img"
                        src={require("../../images/star.png")}
                      ></img>{" "}
                      India’s Best POS <span>ET Hospitality World, 2022</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
              <div className="banner-image hmbanner_img">
                {/* <span></span>
                <span className="hmbanner_img2"></span> */}
                <img
                  className="banr-img pc-imag"
                  alt="Inventory Management Screen of Rista POS Software for restaurant"
                  title="Inventory Management Screen of Rista POS Software for restaurant"
                  src={require("../../images/inventory-webp/Inventory-Main.webp")}
                ></img>
                <img
                  className="banr-img mobile-imag"
                  src={require("../../images/inventory-webp/Inventory-Main.webp")}
                ></img>
              </div>

              <div className="banner-content banner_contt_mob">
                <p className="banner-para">
                  Manage sales, purchasing, and food production effortlessly.
                  Maximize your ingredients and plan ahead to minimize waste.
                </p>
                <div className="ban-bt">
                  <NavLink className="hm_fillbtn" onClick={openModal1}>
                    Get a Demo
                  </NavLink>
                  {/* <a class="ban-second-cta" href="#">Know How/Learn More</a> */}
                </div>
                <div className="banner-mobile">
                  <div className="ban-last">
                    <img
                      className="ban-last-img"
                      src={require("../../images/star.png")}
                    ></img>{" "}
                    Top 20 Technology Startups <span>LinkedIn, 2023</span>
                  </div>
                  <div className="ban-last ban-last-1">
                    <img
                      className="ban-last-img"
                      src={require("../../images/star.png")}
                    ></img>{" "}
                    India’s Best POS <span>ET Hospitality World, 2022</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec-2 direct_sec2 set_logo_section set-inventory-logo-section">
        <div className="container">
          <p className="para">
            Thousands <small>of restaurants</small> run and grow{" "}
            <img src={require("../../images/till-arrow.png")}></img>{" "}
            <small>on</small> RISTA,
            <small> every day.</small>
          </p>
          <BrandLogo />
        </div>
      </section>

      {/* section one */}

      {/* section two */}

      <section className="sec3new sec_space direct_ordr_most inverntry_m_review set_invt_review_sectionn set_big_slides_section">
        <div className="container">
          <div className="sec3new_inner">
            <div className="hm_heading">
              <h5> Industry Leaders Review</h5>
              <h3>
                Why do leading restaurants choose Rista for efficient inventory
                management?
              </h3>
            </div>
          </div>

          <div className="inventery_mcrousel">
            <div className="sec-6 deordr_owl_slides">
              <div className="owl-slider">
                <OwlCarousel
                  items={4}
                  className="owl-theme"
                  loop={true}
                  autoplay={true}
                  nav={true}
                  dots={true}
                  margin={0}
                  responsive={{
                    0: { items: 1, nav: true, dots: true }, // Set 1 item for small screens (width < 600px)
                    600: { items: 1, nav: true, dots: true }, // Set 2 items for medium screens (600px <= width < 960px)
                    1025: { items: 1 }, // Set 4 items for larger screens (width >= 960px)
                  }}
                >
                  <div className="item">
                    <div className="sec-2-item sec-2-item-1">
                      <div className="test-1">
                        <img
                          className="client-img"
                          src={require("../../images/testimony-logos/T-Biriyani-Blues.webp")}
                        ></img>
                        <p>
                          By far the best inventory management system in the
                          market which made our operations smooth & hassle free.
                        </p>
                      </div>
                      <div className="test-2">
                        <div className="client-name">
                          <h5>Aparna Andrews</h5>
                          <p>Co-Founder, Biryani Blues</p>
                        </div>
                        <img
                          src={require("../../images/testimony-people/Niryani-Blues-Aparna-Andrews.webp")}
                        ></img>
                      </div>
                      <img
                        className="test-img"
                        src={require("../../images/test.png")}
                      ></img>
                    </div>
                  </div>
                  <div className="item">
                    <div className="sec-2-item sec-2-item-1">
                      <div className="test-1">
                        <img
                          className="client-img"
                          src={require("../../images/testimony-logos/T-Meghana-Foods.webp")}
                        ></img>
                        <p>
                          Our 5-year association with Rista POS has been of
                          immense importance in our growth journey. They've not
                          only been our partner but also a problem-solving
                          force. They've been a key contributor to our success.
                        </p>
                      </div>
                      <div className="test-2">
                        <div className="client-name">
                          <h5>Vikash Prasad</h5>
                          <p>Founder, Meghana Biryani</p>
                        </div>
                        <img
                          src={require("../../images/testimony-people/Meghana-Foods-Vikash-Prasad.webp")}
                        ></img>
                      </div>
                      <img
                        className="test-img"
                        src={require("../../images/test.png")}
                      ></img>
                    </div>
                  </div>
                  <div className="item">
                    <div className="sec-2-item sec-2-item-1">
                      <div className="test-1">
                        <img
                          className="client-img"
                          src={require("../../images/testimony-logos/T-Truffles.webp")}
                        ></img>
                        <p>
                          We have been using Rista for the last 5 years, our
                          all-in-one tech partner. Rista's advanced inventory &
                          aggregator integrations have helped us improve our
                          operational efficiency. The real-time stock updates
                          have helped us reduce order cancellations.
                        </p>
                      </div>
                      <div className="test-2">
                        <div className="client-name">
                          <h5>Avinash Bajaj</h5>
                          <p>Managing Director, Truffles</p>
                        </div>
                        <img
                          src={require("../../images/testimony-people/Avinash-Avinash-Bajaj.webp")}
                        ></img>
                      </div>
                      <img
                        className="test-img"
                        src={require("../../images/test.png")}
                      ></img>
                    </div>
                  </div>
                  <div className="item">
                    <div className="sec-2-item sec-2-item-1">
                      <div className="test-1">
                        <img
                          className="client-img"
                          src={require("../../images/testimony-logos/T-Indigo.webp")}
                        ></img>
                        <p>
                          At Indigo, we've been using Rista for the past year &
                          a half, & our experience has been nothing short of
                          fabulous. Both their POS & MMS work beautifully, & the
                          reports are crisp. Their service support is brilliant,
                          & I have no hesitation in saying that Rista is a great
                          product for the F&B business.
                        </p>
                      </div>
                      <div className="test-2">
                        <div className="client-name">
                          <h5>Anurag Katriar</h5>
                          <p>Founder, Indigo</p>
                        </div>
                        <img
                          src={require("../../images/testimony-people/Indigo-Anurag-Katriar.webp")}
                        ></img>
                      </div>
                      <img
                        className="test-img"
                        src={require("../../images/test.png")}
                      ></img>
                    </div>
                  </div>
                  <div className="item">
                    <div className="sec-2-item sec-2-item-1">
                      <div className="test-1">
                        <img
                          className="client-img"
                          src={require("../../images/testimony-logos/T-Mod.webp")}
                        ></img>
                        <p>
                          I've always believed in digitization for efficiency, &
                          Rista POS has been the perfect partner in helping us
                          scale our business. They've been a true growth
                          enabler, making our journey smoother & more
                          successful.
                        </p>
                      </div>
                      <div className="test-2">
                        <div className="client-name">
                          <h5>Tarak Bhattacharya</h5>
                          <p>Executive Director & CEO, Mad Over Donuts</p>
                        </div>
                        <img
                          src={require("../../images/testimony-people/MOD-Tarak-Bhattacharya.webp")}
                        ></img>
                      </div>
                      <img
                        className="test-img"
                        src={require("../../images/test.png")}
                      ></img>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section three */}

      <section
        className="sec_space take_control_sec set-invt-control-section"
        ref={sectionRefsss}
      >
        <div className="container">
          <div className="take_controlamin">
            <div className="row">
              <div className="col-md-6">
                <div className="take_con_left">
                  <div className="hm_heading">
                    <h3>
                      Take 100% control of your back-of-the-house operations
                    </h3>
                  </div>
                  <div className="take_conbtn">
                    <a href="javascript:void(0)" onClick={openModal1}>
                      Get a Demo
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mobile-pading-empty">
                <div className="take_con_right desktop-design-slides">
                  <div className="deaktop_takecontrol">
                    <div className="takecon_slide_box">
                      <div className="takecon_img set_invt_takecon_img">
                        {/* <span></span> */}
                        <img
                          className=""
                          alt="Low Inventory Screen on Rista Restaurant Software"
                          title="Low Inventory Screen on Rista Restaurant Software"
                          src={require("../../images/inventory-webp/Always-stay-stocked.webp")}
                        ></img>
                      </div>
                      <div className="takecon_infos">
                        <h4>Always Stay Stocked</h4>
                        <ul>
                          <li>
                            <strong>Low Stock Indicator:</strong> Never run out
                            of stock or overstock again. Our system flags low
                            stock based on your customizable days-of-sale needs,
                            recent sales, and current stock levels.
                          </li>
                          <li>
                            <strong>Days-of-Sale Inventory:</strong> Track
                            inventory not just by units, but also by how many
                            days' worth of sales you have remaining, providing a
                            clearer picture of your stock levels.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="takecon_slide_box">
                      <div className="takecon_img set_invt_takecon_img">
                        {/* <span></span> */}
                        <img
                          className=""
                          alt="Low Inventory Screen on Rista Restaurant Software"
                          title="Low Inventory Screen on Rista Restaurant Software"
                          src={require("../../images/inventory-webp/Streamlined-supply-chain.webp")}
                        ></img>
                      </div>
                      <div className="takecon_infos">
                        <h4>Streamlined Supply Chain</h4>
                        <ul>
                          <li>
                            <strong>Manage Inventory, Reduce Waste: </strong>{" "}
                            Easy-to-use indenting empowers you to manage
                            inventory across outlets, minimize waste, and
                            optimize your entire supply chain.
                          </li>
                          <li>
                            <strong>
                              Reduce Labor Costs & Streamline Inventory
                              Requests:
                            </strong>{" "}
                            Cut out manual work and simplify communication for
                            inventory requests, saving time and money.
                          </li>
                          <li>
                            <strong>Track Transfers:</strong> Accurately track
                            stock transfers between locations, preventing
                            overstocking and minimizing pilferage across your
                            operations.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="takecon_slide_box">
                      <div className="takecon_img set_invt_takecon_img">
                        {/* <span></span> */}
                        <img
                          className=""
                          alt="Low Inventory Screen on Rista Restaurant Software"
                          title="Low Inventory Screen on Rista Restaurant Software"
                          src={require("../../images/inventory-webp/Full-item-transparency.webp")}
                        ></img>
                      </div>
                      <div className="takecon_infos">
                        <h4>Comprehensive Item Transparency</h4>
                        <ul>
                          <li>
                            <strong>Item Activity:</strong> View all actions for
                            any item, including sales, purchases, returns, and
                            audits, for robust planning.
                          </li>
                          <li>
                            <strong>Audit Function: </strong> Conduct quick and
                            easy inventory audits to verify actual stock levels
                            and update your system instantly.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="takecon_slide_box">
                      <div className="takecon_img set_invt_takecon_img">
                        {/* <span></span> */}
                        <img
                          className=""
                          alt="Low Inventory Screen on Rista Restaurant Software"
                          title="Low Inventory Screen on Rista Restaurant Software"
                          src={require("../../images/inventory-webp/Smarter_purchasing_decisions.webp")}
                        ></img>
                      </div>
                      <div className="takecon_infos">
                        <h4>Smarter Purchasing Decisions</h4>
                        <ul>
                          <li>
                            <strong>Purchase Activity:</strong> Track every
                            purchase from your suppliers, including quantity,
                            unit cost, and supplier details, all in one place.
                          </li>
                          <li>
                            <strong>Supplier Activity:</strong> Analyze your
                            spending with any supplier. See exactly what and how
                            much you've bought, allowing for better negotiation
                            and budgeting.
                          </li>
                          <li>
                            <strong>Average Unit Cost:</strong> Gain insights
                            into your average unit cost for each item based on
                            the FIFO method. Utilize this data for informed
                            pricing and promotion strategies.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="takecon_slide_box">
                      <div className="takecon_img set_invt_takecon_img">
                        {/* <span></span> */}
                        <img
                          className=""
                          alt="Low Inventory Screen on Rista Restaurant Software"
                          title="Low Inventory Screen on Rista Restaurant Software"
                          src={require("../../images/inventory-webp/Effortless_returns_management.webp")}
                        ></img>
                      </div>
                      <div className="takecon_infos">
                        <h4>Effortless Returns Management</h4>
                        <ul>
                          <li>
                            <strong>Record Return Items:</strong> Streamline
                            your returns process. Inventory is automatically
                            updated whenever a customer returns an item, saving
                            you time and ensuring accuracy.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    className="owl-slider tabs_owl_crousel"
                    style={{ display: "none" }}
                  >
                    <OwlCarousel
                      ref={carouselRef}
                      {...options}
                      slideBy={1}
                      startPosition={currentSlide}
                    >
                      {/* <OwlCarousel ref={carouselRef} {...carouselOptions}> */}
                      <div class="item">
                        <div className="takecon_slide_box">
                          <div className="takecon_img set_invt_takecon_img">
                            {/* <span></span> */}
                            <img
                              className=""
                              src={require("../../images/inventory-webp/Always-stay-stocked.webp")}
                            ></img>
                          </div>
                          <div className="takecon_infos">
                            <h4>Always Stay Stocked</h4>
                            <ul>
                              <li>
                                <strong>Low Stock Indicator:</strong> Never run
                                out of stock or overstock again. Our system
                                flags low stock based on your customizable
                                days-of-sale needs, recent sales, and current
                                stock levels.
                              </li>
                              <li>
                                <strong>Days-of-Sale Inventory:</strong> Track
                                inventory not just by units, but also by how
                                many days' worth of sales you have remaining,
                                providing a clearer picture of your stock
                                levels.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div className="takecon_slide_box">
                          <div className="takecon_img set_invt_takecon_img">
                            {/* <span></span> */}
                            <img
                              className=""
                              src={require("../../images/inventory-webp/Streamlined-supply-chain.webp")}
                            ></img>
                          </div>
                          <div className="takecon_infos">
                            <h4>Streamlined Supply Chain</h4>
                            <ul>
                              <li>
                                <strong>
                                  Manage Inventory, Reduce Waste:{" "}
                                </strong>{" "}
                                Easy-to-use indenting empowers you to manage
                                inventory across outlets, minimize waste, and
                                optimize your entire supply chain.
                              </li>
                              <li>
                                <strong>
                                  Reduce Labor Costs & Streamline Inventory
                                  Requests:
                                </strong>{" "}
                                Cut out manual work and simplify communication
                                for inventory requests, saving time and money.
                              </li>
                              <li>
                                <strong>Track Transfers:</strong> Accurately
                                track stock transfers between locations,
                                preventing overstocking and minimizing pilferage
                                across your operations.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="item">
                        <div className="takecon_slide_box">
                          <div className="takecon_img set_invt_takecon_img">
                            {/* <span></span> */}
                            <img
                              className=""
                              src={require("../../images/inventory-webp/Streamlined-supply-chain.webp")}
                            ></img>
                          </div>
                          <div className="takecon_infos">
                            <h4>Comprehensive Item Transparency</h4>
                            <ul>
                              <li>
                                <strong>Item Activity:</strong> View all actions
                                for any item, including sales, purchases,
                                returns, and audits, for robust planning.
                              </li>
                              <li>
                                <strong>Audit Function: </strong> Conduct quick
                                and easy inventory audits to verify actual stock
                                levels and update your system instantly.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="item">
                        <div className="takecon_slide_box">
                          <div className="takecon_img set_invt_takecon_img">
                            {/* <span></span> */}
                            <img
                              className=""
                              src={require("../../images/contol-img.png")}
                            ></img>
                          </div>
                          <div className="takecon_infos">
                            <h4>Smarter Purchasing Decisions</h4>
                            <ul>
                              <li>
                                <strong>Purchase Activity:</strong> Track every
                                purchase from your suppliers, including
                                quantity, unit cost, and supplier details, all
                                in one place.
                              </li>
                              <li>
                                <strong>Supplier Activity:</strong> Analyze your
                                spending with any supplier. See exactly what and
                                how much you've bought, allowing for better
                                negotiation and budgeting.
                              </li>
                              <li>
                                <strong>Average Unit Cost:</strong> Gain
                                insights into your average unit cost for each
                                item based on the FIFO method. Utilize this data
                                for informed pricing and promotion strategies.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="item">
                        <div className="takecon_slide_box">
                          <div className="takecon_img set_invt_takecon_img">
                            {/* <span></span> */}
                            <img
                              className=""
                              src={require("../../images/contol-img.png")}
                            ></img>
                          </div>
                          <div className="takecon_infos">
                            <h4>Effortless Returns Management</h4>
                            <ul>
                              <li>
                                <strong>Record Return Items:</strong> Streamline
                                your returns process. Inventory is automatically
                                updated whenever a customer returns an item,
                                saving you time and ensuring accuracy.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>

                <div className="mobile-design-accordian">
                  <div class="accordion" id="control_accordian">
                    <div class="card">
                      <div
                        class="card-header"
                        data-toggle="collapse"
                        data-target="#control_collapseOne"
                        aria-expanded="true"
                      >
                        <span class="title">Always stay stocked </span>
                        <span class="accicon">
                          <b class="plus_icon">+</b>
                          <b class="minus_icon">_</b>
                        </span>
                      </div>
                      <div
                        id="control_collapseOne"
                        class="collapse show"
                        data-parent="#control_accordian"
                      >
                        <div class="card-body">
                          <ul>
                            <li>
                              <strong>Low Stock Indicator:</strong> Never run
                              out of stock or overstock again. Our system flags
                              low stock based on your customizable days-of-sale
                              needs, recent sales, and current stock levels.
                            </li>
                            <li>
                              <strong>Days-of-Sale Inventory:</strong> Track
                              inventory not just by units, but also by how many
                              days' worth of sales you have remaining, providing
                              a clearer picture of your stock levels.
                            </li>
                          </ul>
                          <div class="mobile-accord-img">
                            <img
                              className=""
                              alt="Low Inventory Screen on Rista Restaurant Software"
                              title="Low Inventory Screen on Rista Restaurant Software"
                              src={require("../../images/inventory-webp/Always-stay-stocked.webp")}
                            ></img>
                          </div>

                          <div className="take_conbtn take_conbtn_accords">
                            <a href="javascript:void(0)" onClick={openModal1}>
                              Get a Demo
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div
                        class="card-header collapsed"
                        data-toggle="collapse"
                        data-target="#control_collapseTwo"
                        aria-expanded="false"
                        aria-controls="control_collapseTwo"
                      >
                        <span class="title">Streamlined supply chain</span>
                        <span class="accicon">
                          <b class="plus_icon">+</b>
                          <b class="minus_icon">_</b>
                        </span>
                      </div>
                      <div
                        id="control_collapseTwo"
                        class="collapse"
                        data-parent="#control_accordian"
                      >
                        <div class="card-body">
                          <ul>
                            <li>
                              <strong>Manage inventory, reduce waste: </strong>{" "}
                              Easy-to-use indenting empowers you to manage
                              inventory across outlets, minimize waste, and
                              optimize your entire supply chain.
                            </li>
                            <li>
                              <strong>
                                Reduce Labor Costs & Streamline Inventory
                                Requests:
                              </strong>{" "}
                              Cut out manual work and simplify communication for
                              inventory requests, saving time and money.
                            </li>
                            <li>
                              <strong>Track Transfers:</strong> Accurately track
                              stock transfers between locations, preventing
                              overstocking and minimizing food waste across your
                              operations.
                            </li>
                          </ul>

                          <div className="take_conbtn take_conbtn_accords">
                            <a href="javascript:void(0)" onClick={openModal1}>
                              Get a Demo
                            </a>
                          </div>
                          <div class="mobile-accord-img">
                            <img
                              className=""
                              alt="Low Inventory Screen on Rista Restaurant Software"
                              title="Low Inventory Screen on Rista Restaurant Software"
                              src={require("../../images/inventory-webp/Streamlined-supply-chain.webp")}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div
                        class="card-header collapsed"
                        data-toggle="collapse"
                        data-target="#control_collapseThree"
                        aria-expanded="false"
                      >
                        <span class="title">Full item transparency</span>
                        <span class="accicon">
                          <b class="plus_icon">+</b>
                          <b class="minus_icon">_</b>
                        </span>
                      </div>
                      <div
                        id="control_collapseThree"
                        class="collapse"
                        data-parent="#control_accordian"
                      >
                        <div class="card-body">
                          <ul>
                            <li>
                              <strong>Item Activity:</strong> View all actions
                              for any item, including sales, purchases, returns,
                              and audits, for robust planning.
                            </li>
                            <li>
                              <strong>Audit Function: </strong> Conduct quick
                              and easy inventory audits to verify actual stock
                              levels and update your system instantly.
                            </li>
                          </ul>
                          <div className="take_conbtn take_conbtn_accords">
                            <a href="javascript:void(0)" onClick={openModal1}>
                              Get a Demo
                            </a>
                          </div>
                          <div class="mobile-accord-img">
                            <img
                              className=""
                              alt="Low Inventory Screen on Rista Restaurant Software"
                              title="Low Inventory Screen on Rista Restaurant Software"
                              src={require("../../images/inventory-webp/Full-item-transparency.webp")}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div
                        class="card-header collapsed"
                        data-toggle="collapse"
                        data-target="#control_collapseFour"
                        aria-expanded="false"
                      >
                        <span class="title">Smarter purchasing decisions</span>
                        <span class="accicon">
                          <b class="plus_icon">+</b>
                          <b class="minus_icon">_</b>
                        </span>
                      </div>
                      <div
                        id="control_collapseFour"
                        class="collapse"
                        data-parent="#control_accordian"
                      >
                        <div class="card-body">
                          <ul>
                            <li>
                              <strong>Purchase Activity:</strong> Track every
                              purchase from your suppliers, including quantity,
                              unit cost, and supplier details, all in one place.
                            </li>
                            <li>
                              <strong>Supplier Activity:</strong> Analyze your
                              spending with any supplier. See exactly what and
                              how much you've bought, allowing for better
                              negotiation and budgeting.
                            </li>
                            <li>
                              <strong>Average Unit Cost:</strong> Gain insights
                              into your average unit cost for each item based on
                              the FIFO method. Utilize this data for informed
                              pricing and promotion strategies.
                            </li>
                          </ul>
                          <div className="take_conbtn take_conbtn_accords">
                            <a href="javascript:void(0)" onClick={openModal1}>
                              Get a Demo
                            </a>
                          </div>
                          <div class="mobile-accord-img">
                            <img
                              className=""
                              alt="Low Inventory Screen on Rista Restaurant Software"
                              title="Low Inventory Screen on Rista Restaurant Software"
                              src={require("../../images/inventory-webp/Smarter_purchasing_decisions.webp")}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div
                        class="card-header collapsed"
                        data-toggle="collapse"
                        data-target="#control_collapseFive"
                        aria-expanded="false"
                      >
                        <span class="title">Effortless returns management</span>
                        <span class="accicon">
                          <b class="plus_icon">+</b>
                          <b class="minus_icon">_</b>
                        </span>
                      </div>
                      <div
                        id="control_collapseFive"
                        class="collapse"
                        data-parent="#control_accordian"
                      >
                        <div class="card-body">
                          <ul>
                            <li>
                              <strong>Record Return Items:</strong> Streamline
                              your returns process. Inventory is automatically
                              updated whenever a customer returns an item,
                              saving you time and ensuring accuracy.
                            </li>
                          </ul>

                          <div className="take_conbtn take_conbtn_accords">
                            <a href="javascript:void(0)" onClick={openModal1}>
                              Get a Demo
                            </a>
                          </div>
                          <div class="mobile-accord-img">
                            <img
                              className=""
                              alt="Low Inventory Screen on Rista Restaurant Software"
                              title="Low Inventory Screen on Rista Restaurant Software"
                              src={require("../../images/inventory-webp/Effortless_returns_management.webp")}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-3 sec3_home sec3_inverntry set_invt_choose_rista inven_choose_rista sec3_home_tabs ">
        <div className="container">
          <h2 className="subheading">
            Choose Rista and get all the functionality
            <span> you need for your restaurant business.</span>
          </h2>
        </div>
        <div className="hm_tabsouter">
          <div className="hmtabs_mainbox">
            <div className="rista_tabs">
              <div className="container">
                <ul
                  className="nav nav-pills"
                  // className={`nav nav-pills ${
                  //   isSectionVisible ? "nav-active" : ""
                  // }`}
                  ref={sectionRefs}
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="pill"
                      href="#flamingo"
                      role="tab"
                      aria-controls="pills-flamingo"
                      aria-selected="true"
                      onClick={scrollToSection}
                    >
                      Fine-Dine
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#cuckoo"
                      role="tab"
                      aria-controls="pills-cuckoo"
                      aria-selected="false"
                      onClick={scrollToSection}
                    >
                      Quick Service Restaurant
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#ostrich"
                      role="tab"
                      aria-controls="pills-ostrich"
                      aria-selected="false"
                      onClick={scrollToSection}
                    >
                      Cloud Kitchen
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#pubs"
                      role="tab"
                      aria-controls="pills-pubs"
                      aria-selected="false"
                      onClick={scrollToSection}
                    >
                      Pubs, Bars, Clubs and Lounges
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="container">
              <div className="tab_blockinfo_main">
                <div className="tabs_infos_box">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="flamingo"
                      role="tabpanel"
                      aria-labelledby="flamingo-tab"
                    >
                      <div className="point_block_main">
                        <div className="point_row_grid">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="point_box">
                                <h5 class="tab-heading-for-mobile">
                                  Fine Dining
                                </h5>
                                <h3>Effortless control, maximum efficiency</h3>
                                <div className="tabs-mobile-image">
                                  <img
                                    className=""
                                    alt="Delicious Food Served in a dish in fine dine restaurant using Rista restaurant pos software"
                                    title="Delicious Food Served in a dish in fine dine restaurant using Rista restaurant pos software"
                                    src={require("../../images/inventory-webp/Inv-Fine-Dine.webp")}
                                  ></img>
                                </div>
                                <div className="points_tick">
                                  <ul>
                                    <li>
                                      Forecast demand and create data-driven
                                      menus to ensure you use every ingredient
                                      efficiently.
                                    </li>
                                    <li>
                                      Track ingredients by lot or expiration
                                      date to guarantee consistent freshness.
                                    </li>
                                    <li>
                                      Connect seamlessly with suppliers. Gain
                                      real-time inventory visibility, automate
                                      orders, and negotiate better sourcing
                                      costs. Reduce costs and build trust with
                                      your partners.
                                    </li>
                                  </ul>
                                </div>
                                <p>
                                  <small>
                                    Focus on what matters most - creating
                                    unforgettable culinary experiences.
                                  </small>
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="point_img point_noimg tabs-desktop-image">
                                {/* <span></span> */}
                                <img
                                  className=""
                                  alt="Delicious Food Served in a dish in fine dine restaurant using Rista restaurant pos software"
                                  title="Delicious Food Served in a dish in fine dine restaurant using Rista restaurant pos software"
                                  src={require("../../images/inventory-webp/Inv-Fine-Dine.webp")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="cuckoo"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="point_block_main">
                        <div className="point_row_grid">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="point_box">
                                <h5 class="tab-heading-for-mobile">
                                  Quick Service Restaurant
                                </h5>
                                <h3>Serve Up Speed, Slash Costs</h3>
                                <div className="tabs-mobile-image">
                                  <img
                                    className=""
                                    alt="Rista POS implementation for QSRs"
                                    title="Rista POS implementation for QSRs"
                                    src={require("../../images/inventory-webp/Quick-Service-Restaurants.webp")}
                                  ></img>
                                </div>
                                <div className="points_tick">
                                  <ul>
                                    <li>
                                      Optimize stock levels for peak periods.
                                      Say goodbye to “out-of-stock” and
                                      frustrated customers.
                                    </li>
                                    <li>
                                      Track inventory by location. Identify
                                      discrepancies and prevent waste, keeping
                                      your bottom line healthy.
                                    </li>
                                    <li>
                                      Reduce prep time and optimize operations
                                      with real-time inventory insights. Create
                                      efficient prep schedules, minimize waste,
                                      and free up staff for customer service.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="point_img point_noimg tabs-desktop-image">
                                {/* <span></span> */}
                                <img
                                  className=""
                                  alt="Rista POS implementation for QSRs"
                                  title="Rista POS implementation for QSRs"
                                  src={require("../../images/inventory-webp/Quick-Service-Restaurants.webp")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="ostrich"
                      role="tabpanel"
                      aria-labelledby="ostrich-tab"
                    >
                      <div className="point_block_main">
                        <div className="point_row_grid">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="point_box">
                                <h5 class="tab-heading-for-mobile">
                                  Cloud Kitchen
                                </h5>
                                <h3>No kitchen, no problem.</h3>
                                <div className="tabs-mobile-image">
                                  <img
                                    className=""
                                    alt="Recipe Management in Rista POS Software"
                                    title="Recipe Management in Rista POS Software"
                                    src={require("../../images/inventory-webp/Limited-space-limitless-possibilities.webp")}
                                  ></img>
                                </div>
                                <div className="points_tick">
                                  <ul>
                                    <li>
                                      Minimize dead stock. With real-time
                                      ingredient tracking, you can precisely
                                      forecast demand and order exactly what you
                                      need.
                                    </li>
                                    <li>
                                      Embrace agility and conquer rush hours.
                                      Automate reorders, helping cloud kitchens
                                      adapt to fluctuating demands and handle
                                      surges in online orders.
                                    </li>
                                    <li>
                                      Track recipe costs and analyze sales data.
                                      Fine-tune your menu pricing and promote
                                      high-margin dishes for maximum
                                      profitability.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="point_img point_noimg tabs-desktop-image">
                                {/* <span></span> */}
                                <img
                                  className=""
                                  alt="Recipe Management in Rista POS Software"
                                  title="Recipe Management in Rista POS Software"
                                  src={require("../../images/inventory-webp/Limited-space-limitless-possibilities.webp")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pubs"
                      role="tabpanel"
                      aria-labelledby="pubs-tab"
                    >
                      <div className="point_block_main">
                        <div className="point_row_grid">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="point_box">
                                <h5 class="tab-heading-for-mobile">
                                  Pubs, Bars, Clubs and Lounges
                                </h5>
                                <h3>Optimize Inventory, Maximize Profits</h3>
                                <div className="tabs-mobile-image">
                                  <img
                                    className=""
                                    alt="Recipe Management in Rista POS Software"
                                    title="Recipe Management in Rista POS Software"
                                    src={require("../../images/inventory-webp/Limited-space-limitless-possibilities.webp")}
                                  ></img>
                                </div>
                                <div className="points_tick">
                                  <ul>
                                    {" "}
                                    <li>
                                      Ensure your venue is always stocked with
                                      the essentials by automating reorder
                                      processes, and avoiding stockouts.
                                    </li>
                                    <li>
                                      Track which drinks are most popular to
                                      adjust inventory accordingly and ensure
                                      guest delight.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="point_img point_noimg tabs-desktop-image">
                                {/* <span></span> */}
                                <img
                                  className=""
                                  alt="Recipe Management in Rista POS Software"
                                  title="Recipe Management in Rista POS Software"
                                  src={require("../../images/inventory-webp/Limited-space-limitless-possibilities.webp")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Navigation arrows */}
      </section>

      <section className="sec_space know_your_Inventry set-know-invt">
        <div className="container">
          <div className="hm_heading">
            <h3>Know your inventory inside out</h3>
          </div>
          <div className="inventory-colum">
            <div className="row">
              <div className="col-md-4">
                <div className="inventory-box">
                  <h4 className="inventory-box-heading">Total stock control</h4>
                  <p className="inventory-box-pera">
                    Gain visibility, monitor movements, and set smart reorder
                    points to minimize stockouts and excess inventory.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inventory-box">
                  <h4 className="inventory-box-heading">
                    Offline connectivity
                  </h4>
                  <p className="inventory-box-pera">
                    Manage inventory even while being offline and sync data
                    effortlessly upon reconnecting.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inventory-box">
                  <h4 className="inventory-box-heading">
                    Centralized stock management
                  </h4>
                  <p className="inventory-box-pera">
                    Categorize stock, automate replenishment, and access
                    real-time valuations to reduce costs and prevent
                    obsolescence.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inventory-box">
                  <h4 className="inventory-box-heading">Actionable insights</h4>
                  <p className="inventory-box-pera">
                    Use analytics to track turnover, identify aging stock, and
                    forecast demand for data-driven decisions.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inventory-box">
                  <h4 className="inventory-box-heading">
                    Seamless sales channel management
                  </h4>
                  <p className="inventory-box-pera">
                    Synchronize inventory across e-commerce, stores, and
                    marketplaces to avoid overselling and underselling.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inventory-box">
                  <h4 className="inventory-box-heading">
                    Easy order fulfillment
                  </h4>
                  <p className="inventory-box-pera">
                    Prioritize orders, manage backorders, and track shipments to
                    improve accuracy and customer satisfaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-bar-up set_invt-footerbar">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div class="fotr_barup_infos">
                <h4>
                  Ready to transform your inventory management and maximize your
                  profits?
                </h4>
                <h2 class="subheading subheading_top">
                  Get in touch with our team today!
                </h2>
              </div>

              {/* <h2 className="subheading">
              </h2> */}

              {/* <h3 className="heading">We're here to help!</h3> */}
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="right-side">
                {/* <h4>Schedule your free demo today</h4> */}
                <h5>
                  We will reach out within 24 hours to schedule your slot.
                </h5>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile Number"
                    ref={footerMobileRef}
                    name="mobileNumberfooter"
                    value={footerMobile}
                    onChange={handleChangeFooterForm}
                    maxLength={10}
                  />
                  {hasFooterMobileErrorText && (
                    <span className="error" style={{ color: "red" }}>
                      {hasFooterMobileErrorText}
                    </span>
                  )}
                </div>
                <button
                  className="btn btn-primary bt-form"
                  onClick={() => {
                    if (validateFooterMobileForm()) {
                      setHasFooterMobileErrorText("");
                      openModal1();
                    }
                  }}
                >
                  Get a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section seven */}

      <section className="sec-8 faq_btm set_faq_section">
        <div className="container">
          <h2 className="subheading">Frequently asked questions</h2>
          <div className="row mid">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div
                className="accordion accordion_rista accordion_rista2 accordion_rista3"
                id="faqaccord6"
              >
                <div className="card">
                  <div className="card-header" id="faqhead5">
                    <a
                      href="#"
                      className="btn btn-header-link"
                      data-toggle="collapse"
                      data-target="#faq4"
                      aria-expanded="true"
                      aria-controls="faq4"
                    >
                      How can RISTA help me reduce waste and maximize profit?
                    </a>
                  </div>

                  <div
                    id="faq4"
                    className="collapse show"
                    aria-labelledby="faqhead4"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        Rista's intelligent system automates ordering based on
                        real-time sales data and customizable settings. This
                        eliminates overstocking and ensures you have the perfect
                        amount of ingredients, minimizing waste and maximizing
                        your profit margins.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqhead5">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq5"
                      aria-expanded="true"
                      aria-controls="faq5"
                    >
                      What makes RISTA different from other inventory management
                      solutions?
                    </a>
                  </div>

                  <div
                    id="faq5"
                    className="collapse"
                    aria-labelledby="faqhead5"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        Rista goes beyond simple inventory tracking. It offers
                        features like automated ordering, automatic low-stock
                        alerts, and insightful reporting tools. This empowers
                        you to make data-driven decisions and optimize your
                        entire supply chain for efficiency and cost savings.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqhead6">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq6"
                      aria-expanded="true"
                      aria-controls="faq6"
                    >
                      Is RISTA easy to use, even for restaurants without
                      experience?
                    </a>
                  </div>

                  <div
                    id="faq6"
                    className="collapse"
                    aria-labelledby="faqhead6"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        Absolutely! Rista is designed with a user-friendly
                        interface and intuitive features. We also offer
                        comprehensive onboarding resources and ongoing customer
                        support to ensure a smooth transition.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqhead7">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq7"
                      aria-expanded="true"
                      aria-controls="faq7"
                    >
                      Can I see a free demo of RISTA before I commit?
                    </a>
                  </div>

                  <div
                    id="faq7"
                    className="collapse"
                    aria-labelledby="faqhead7"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        Yes! We encourage you to see Rista in action. Schedule a
                        free demo with our team to experience firsthand how
                        Rista can transform your inventory management. (Consider
                        adding a button here that says "Schedule a Free Demo")
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqhead8">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq8"
                      aria-expanded="true"
                      aria-controls="faq8"
                    >
                      Does RISTA integrate with my existing POS system?
                    </a>
                  </div>

                  <div
                    id="faq8"
                    className="collapse"
                    aria-labelledby="faqhead8"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        Rista integrates seamlessly with popular POS systems.
                        This allows you to streamline your operations and manage
                        inventory data from a single platform. (Consider
                        mentioning compatible POS systems here if applicable)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ban-bt">
                <NavLink onClick={openModal1}>Try Now</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <div className="personal_modal_new business_modal_new">
        <Modal
          isOpen={modalIsOpen1}
          onRequestClose={closeModal1}
          // onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          className="customModalContent"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
          {/* <button onClick={closeModal}>close</button> */}
          <div className="dotpe_popouter dotpe_popouter_new">
            <span
              className="closebtn_new"
              onClick={() => {
                closeModal1();
              }}
            >
              <img src={require("../../images/close-cross.png")} alt="Logo" />
            </span>
            <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
              <div className="dotpe_left col-md-5">
                <div className="dotpe_left_infos">
                  <h3>You are taking a right step for your business.</h3>
                  <h4>Trusted by 75 lakh+ businesses in india</h4>
                  <div className="dotpe_poplogo">
                    <img src={require("../../images/logo-w.png")} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="dotpe_right  col-md-7">
                <div className="dotpe_right_infos">
                  <div className="busi_loan_form">
                    <form>
                      <div className="form_heading">
                        <h3>Setup Free Demo</h3>
                        <p>
                          Fill in the details & our product specialist will
                          reach out to you
                        </p>
                      </div>
                      <div className={formClassName}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          ref={inputName}
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {formErrors.name && (
                          <span className="error">{formErrors.name}</span>
                        )}
                      </div>
                      <div className={formClassName}>
                        {/* <div>
                          <select
                            className="form-control"
                            // value={formData.countryCode}
                            // onChange={handleChange}
                            // name="countryCode"
                          >
                              <option >
                                +91
                              </option>
                          </select>
                        </div> */}
                        <div className="mobilenum">
                          <span>+91</span>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile Number"
                            ref={inputNumber}
                            name="mobileNumber"
                            value={
                              footerMobile != ""
                                ? (formData.mobileNumber = footerMobile)
                                : formData.mobileNumber
                            }
                            onChange={handleChange}
                            maxLength={10}
                          />
                          {formErrors.mobileNumber && (
                            <span className="error">
                              {formErrors.mobileNumber}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={formClassName}>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          ref={inputEmail}
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {formErrors.email && (
                          <span className="error">{formErrors.email}</span>
                        )}
                      </div>

                      <div className="form_click">
                        By clicking on continue, I hereby provide my consent to
                        receive emails, phone calls, messages and other
                        communications from DotPe to understand about DotPe's
                        services, offerings, promotions and other related
                        information.
                      </div>

                      <div className="formlead_btn">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            if (validateSecondForm()) {
                              validateAndOpenModal2();
                            }
                          }}
                          // onClick={validateAndOpenModal2}
                        >
                          Request Demo
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div className="personal_modal_new business_modal_new">
        <Modal
          isOpen={modalIsOpen2}
          onRequestClose={closeModal2}
          style={customStyles}
          contentLabel="Example Modal"
          className="customModalContent"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
          {/* <button onClick={closeModal}>close</button> */}
          <div className="dotpe_popouter dotpe_popouter_new">
            <span
              className="closebtn_new"
              onClick={() => {
                closeModal2();
              }}
            >
              <img src={require("../../images/close-cross.png")} alt="Logo" />
            </span>
            <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
              <div className="dotpe_left col-md-5 dotpe_left2_mode">
                <div className="dotpe_left_infos  dotpe_left2_mode_infos">
                  <div className="dotpe_popcheck">
                    <img src={successcheck2} alt="Icon" />
                  </div>
                  <h4>You have successfully scheduled a call with us.</h4>
                  <div className="dotpe_poplogo">
                    <img src={require("../../images/logo-w.png")} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="dotpe_right  col-md-7">
                <div className="dotpe_right_infos">
                  <div className="busi_loan_form">
                    <form>
                      <div className="form_heading">
                        <h3>
                          Our team will reach out to you in next few minutes
                        </h3>
                        <p>Meanwhile tell us more about your 1 business day.</p>
                      </div>

                      <div className="form-group form_group_nice">
                        <label>Select a business category</label>
                        <NiceSelect
                          id="a-select"
                          placeholder="Select..."
                          className="sampleClass"
                          name="incomeSource"
                          value={formData.incomeSource}
                          onChange={handleChange}
                        >
                          <option value="Food and beverage">
                            Food and beverage
                          </option>
                          <option value="Clothing and accessories">
                            Clothing and accessories
                          </option>
                          <option value="Bakery">Bakery</option>
                          <option value="Salon">Salon</option>
                          <option value="Home Decor and furnishing">
                            Home Decor and furnishing
                          </option>
                          <option value="eCommerce">eCommerce</option>
                          <option value="Services">Services</option>
                        </NiceSelect>
                        {formErrors.incomeSource && (
                          <span className="error">
                            {formErrors.incomeSource}
                          </span>
                        )}
                      </div>
                      <div className="form-group form_group_nice form_group_nice2">
                        <label>Select your role in this business.</label>
                        <NiceSelect
                          id="a-select1"
                          placeholder="Select..."
                          className="sampleClass"
                          name="role"
                          value={formData.role}
                          onChange={handleChange}
                        >
                          <option value="Owner">Owner</option>
                          <option value="Store Manager">Store Manager</option>
                          <option value="Accounts">Accounts</option>
                          <option value="Sales">Sales</option>
                        </NiceSelect>
                        {formErrors.role && (
                          <span className="error">{formErrors.role}</span>
                        )}
                      </div>
                      <div className="formlead_btn formlead_btn2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          // onClick={() => {
                          //   if (validateSecondForm()) {
                          //     saveDetails();
                          //   }
                          // }}
                          onClick={saveDetails}
                        >
                          Done
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default RestaurantInventoryManagement;


