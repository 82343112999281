import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <section className="temrms_main_infos">
        <div className="container">
          <div className="terms_infos">
            <div className="auxilary_head">
              <h2>Privacy Policy</h2>
              <p>
                Please read this privacy policy carefully by accessing or using
                the website/mobile application. You agree to be bound by the
                terms described herein. If you do not agree to all of these
                terms, please do not use the website/mobile application. Using
                the website/mobile application will be considered as a deemed
                acceptance of this policy.
              </p>
            </div>
            <div className="terms_content">
              <div className="terms_block">
                <h4>Content & Purpose</h4>
                <p>
                  This privacy policy (“Privacy Policy”) applies to your use of
                  the domain name/website www.ristaapps.com, an internet based
                  portal, and our mobile application, owned/operated by Dotpe
                  Private Limited, a company duly incorporated under the
                  provisions of the Indian Companies Act (hereinafter referred
                  to as “Dotpe” or “we” or “our” or “us” or “Company”). The
                  domain name and the mobile application are collectively
                  referred to as the “Website”.
                </p>
                <p>
                  The Website is a digital platform that inter-alia enables the
                  enterprises to connect to its customers by way of integration
                  of social media platforms, POS system/software, in store
                  ordering & billing system and bank/payment gateways and
                  delivery partners (hereinafter referred to as “Services”). The
                  Services would be made available to such persons who
                  visits/uses the Website after obtaining due
                  registration/accepting necessary terms and conditions, in
                  accordance with the procedure as determined by us, from time
                  to time (referred to as “you” or “your” or “yourself” or
                  “User”).
                </p>
                <p>
                  We have implemented reasonable security practices and
                  procedures that are commensurate with the information assets
                  being protected and with the nature of our business. While we
                  try our best to provide security that is commensurate with the
                  industry standards, because of the inherent vulnerabilities of
                  the internet, we cannot ensure or warrant complete security of
                  all information that is being transmitted to us by you.
                </p>
                <p>
                  For the purpose of providing the Services and for other
                  purposes identified in this Privacy Policy, we will be
                  required to collect and host certain data and information of
                  the Users. We are committed to protecting the Personal
                  Information (as defined below) of the Users and take all
                  reasonable precautions for maintaining confidentiality of the
                  User’s Personal Information. This Privacy Policy has been
                  designed and developed to help You to understand the
                  following:
                </p>

                <ul>
                  <li>
                    The type of Personal Information (including Sensitive
                    Personal Data or Information) that we collect from the
                    Users;
                  </li>
                  <li>
                    The purpose of collection, means and modes of usage of such
                    Personal Information by the Company;
                  </li>
                  <li>
                    How and to whom the Company will disclose such information;
                  </li>
                  <li>
                    How the Company will protect the Personal Information
                    including Sensitive Personal Data or Information that is
                    collected from the Users; and
                  </li>
                  <li>
                    How Users may access and/or modify their Personal
                    Information.
                  </li>
                </ul>

                <p>
                  This Privacy Policy shall apply to the use of the Website by
                  all Users. Accordingly, a condition of each User's use of and
                  access to the Website and to the other services provided by
                  the Company to Users is their acceptance of this Privacy
                  policy. Any User is required to read and understand the
                  provisions set out herein prior to submitting any Sensitive
                  Personal Data or Information to the Company, failing which
                  they are required to leave the Website immediately.
                </p>

                <p>
                  This Privacy Policy is published in compliance with the
                  (Indian) Information Technology Act, 2000 and the rules,
                  regulations, guidelines and clarifications framed thereunder,
                  including the (Indian) Information Technology (Reasonable
                  Security Practices and Procedures and Sensitive Personal Data
                  or Information) Rules, 2011.
                </p>
              </div>

              <div className="terms_block">
                <h4>Personal Information</h4>

                <p>
                  “Personal Information” means any information that relates to a
                  natural person, which, either directly or indirectly, in
                  combination with other information available with the Company,
                  is capable of identifying the person concerned.
                </p>
                <p>
                  “Sensitive Personal Data or Information” means Personal
                  Information of any individual relating to password; financial
                  information such as bank account or credit card or debit card
                  or other payment instrument details; physical, physiological
                  and mental health condition; sexual orientation; medical
                  records and history; biometric information; any detail
                  relating to the above as provided to or received by the
                  Company for processing or storage. However, any
                  data/information relating to an individual that is freely
                  available or accessible in public domain or furnished under
                  the Right to Information Act, 2005 or any other law shall not
                  qualify as Sensitive Personal Data or Information.
                </p>
              </div>

              <div className="terms_block">
                <h4>Types of Personal Information collected by the Company</h4>

                <p>
                  A User may have limited access to the Website and utilize some
                  of the Services provided by the Company without creating an
                  account on the Website. Unregistered Users can access some of
                  the information and details available on the Website. In order
                  to have access to all the features and benefits on our
                  Website, a User may be required to first create an account on
                  our Website. As part of the registration process, we may
                  collect the following categories of Personal Information from
                  the Users (hereinafter collectively referred to as “User
                  Information”):
                </p>

                <ul>
                  <li>Name;</li>
                  <li>User ID;</li>
                  <li>Email address;</li>
                  <li>Address (including country and ZIP/postal code);</li>
                  <li>Gender;</li>
                  <li>Age;</li>
                  <li>Phone Number;</li>
                  <li>Password chosen by the User;</li>
                  <li>Contacts;</li>
                  <li>Precise/Geographical location;</li>
                  <li>Photos or Videos;</li>
                  <li>Customer support related information/details;</li>
                  <li>Product interaction related information/details;</li>
                  <li>Crash and performance data;</li>
                  <li>Valid payment/financial/account information; and</li>
                  <li>Other details as the User may voluntarily provide.</li>
                </ul>
              </div>

              <div className="terms_block">
                <h4>Internet Use</h4>

                <p>
                  We may also receive and/or hold information about the User's
                  browsing history including the URL of the site that the User
                  visited prior to visiting the website as well as the Internet
                  Protocol (IP) address of each User's computer (or the proxy
                  server a User used to access the World Wide Web), User's
                  computer operating system and type of web browser the User is
                  using as well as the name of User's ISP. The Website uses
                  temporary cookies to store certain data (that is not Sensitive
                  Personal Data or Information) that is used by us for the
                  technical administration of the Website, research and
                  development, and for User administration.
                </p>

                <p>
                  In addition, We may in future include other optional requests
                  for information from the User including through user surveys
                  in order to help us customize the website to deliver
                  personalized information to the User and for other purposes
                  are mentioned herein. Such information may also be collected
                  in the course of contests conducted by us. Any such additional
                  Personal Information will also be processed in accordance with
                  this Privacy Policy.
                </p>
              </div>

              <div className="terms_block">
                <h4>Purposes for which the Company may use the Information</h4>

                <p>
                  We will retain User Information only to the extent it is
                  necessary to provide Services to the Users and for any longer
                  period thereafter in order to comply with the applicable laws.
                  The information, which we collect from you, may be utilized
                  for various business and/or regulatory purposes including for
                  the following purposes:
                </p>

                <ul>
                  <li> Registration of the User on the Website;</li>
                  <li>
                    Processing the User's orders/requests and provision of
                    Services;
                  </li>
                  <li>Sending timely/periodical updates to the Users;</li>
                  <li>
                    Completing transactions with Users effectively and billing
                    for the products/Services provided;
                  </li>
                  <li>
                    Technical administration and customization of Website;
                  </li>
                  <li>
                    Ensuring that the Website content is presented to the Users
                    in an effective manner;
                  </li>
                  <li>
                    Delivery of personalized information and target
                    advertisements to the User;
                  </li>
                  <li>
                    Improvement of Services, features and functionality of the
                    Website; providing an optimal experience and improving the
                    Website;
                  </li>
                  <li>
                    Research and development and for User administration
                    (including conducting user surveys);
                  </li>
                  <li>
                    For purposes of research, analysis, business intelligence,
                    reporting and improvement/development/advancement of the
                    Company’s business, Website and/or the Services;
                  </li>
                  <li>
                    Non-personally identifiable information, exclusively owned
                    by the Company may be used in an aggregated or
                    non-personally identifiable form for internal research,
                    statistical analysis and business intelligence purposes
                    including those for the purposes of determining the number
                    of visitors and transactional details, and we may sell or
                    otherwise transfer such research, statistical or
                    intelligence data in an aggregated or non-personally
                    identifiable form to third parties and affiliates;
                  </li>
                  <li>
                    Dealing with requests, enquiries, complaints or disputes and
                    other customer care related activities including those
                    arising out of the Users’ request of the Services and all
                    other general administrative and business purposes;
                  </li>
                  <li>
                    Communicate any changes in our Services or this Privacy
                    Policy or the Terms of Use to the Users;
                  </li>
                  <li>
                    Verification of identity of Users and perform checks to
                    prevent frauds; and
                  </li>
                  <li>
                    Investigating, enforcing, resolving disputes and applying
                    our Terms of Use and Privacy Policy, either ourselves or
                    through third party service providers.
                  </li>
                  <li>
                    To comply with applicable legal requirements and our various
                    policies/terms; and
                  </li>
                  <li>
                    Any other purpose that may be necessary to provide the
                    Services that You have opted for.
                  </li>
                </ul>
              </div>

              <div className="terms_block">
                <h4>Disclosure and Transfer of User’s Personal Information</h4>

                <p>
                  {" "}
                  A. We may need to disclose/transfer User's Personal
                  Information to Governmental and judicial
                  institutions/authorities, to the extent required: Under the
                  laws, rules, and regulations and/or under orders of any
                  relevant judicial or quasi-judicial authority;
                </p>
                <p>
                  B. To protect and defend the rights or property of the
                  Company;
                </p>
                <p>C. To fight fraud and credit risk;</p>
                <p>
                  D. To enforce the Company's Terms of Use (to which this
                  Privacy Policy is also a part); or
                </p>
                <p>
                  E. When the Company, in its sole discretion, deems it
                  necessary in order to protect its rights or the rights of
                  others.
                </p>

                <p>
                  If otherwise required by an order under any law for the time
                  being in force including in response to enquiries by
                  Government agencies for the purpose of verification of
                  identity, or for prevention, detection, investigation
                  including cyber incidents, prosecution, and punishment of
                  offences.
                </p>

                <p>
                  The Company make all User Information accessible to its
                  employees and data processors/third party vendors only on a
                  need-to-know basis. All the employees and data processors, who
                  have access to, and are associated with the processing of User
                  Information, are obliged to respect its confidentiality.
                </p>

                <p>
                  Non-personally identifiable information may be disclosed to
                  third party ad servers, ad agencies, technology vendors and
                  research firms to serve advertisements to the Users. The
                  Company may also share its aggregate findings (not specific
                  information) based on information relating to the User’s
                  internet use to prospective, investors, strategic partners,
                  sponsors and others in order to help growth of Company's
                  business.
                </p>

                <p>
                  We may also disclose or transfer the User Information, to
                  another third party as part of reorganization or a sale of the
                  assets or business of Company. Any third party to which the
                  Company transfers or sells its assets will have the right to
                  continue to use the Personal Information and/or other
                  information that a User provides to us.
                </p>
              </div>

              <div className="terms_block">
                <h4>Links to Third-Party</h4>

                <p>
                  The links to third-party advertisements, third party websites
                  or any third party electronic communication services (referred
                  to as “Third Party Links”) may be provided on the Website
                  which are operated by third parties and are not controlled by,
                  or affiliated to, or associated with the Company, unless
                  expressly specified on the Website.
                </p>

                <p>
                  If You access any such Third Party Links, we request You
                  review the website’s privacy policy. We are not responsible
                  for the policies or practices of Third Party Links.
                </p>
              </div>

              <div className="terms_block">
                <h4>Security Practices and Procedures</h4>

                <p>
                  For the purpose of providing the Services and for other
                  purposes identified in this Privacy Policy, we are required to
                  collect and host certain data and information of the Users. We
                  are committed to protecting Your Personal Information, and to
                  that end, the Company adopts reasonable security practices and
                  procedures to implement technical, operational, managerial and
                  physical security control measures in order to protect the
                  Personal Information in its possession from loss, misuse and
                  unauthorized access, disclosure, alteration and destruction.
                  While we try our best to provide security that is commensurate
                  with the industry standards, due to the inherent
                  vulnerabilities of the internet, we cannot ensure or warrant
                  complete security of all information that is being transmitted
                  to Us. The Company takes adequate steps to ensure that third
                  parties to whom the Users’ Sensitive Personal Data or
                  Information may be transferred adopt reasonable level of
                  security practices and procedures to ensure security of
                  Personal Information.
                </p>

                <p>
                  You hereby acknowledge that the Company is not responsible for
                  any intercepted information sent via the internet, and you
                  hereby release us from any and all claims arising out of or
                  related to the use of intercepted information in any
                  unauthorized manner.
                </p>
              </div>

              <div className="terms_block">
                <h4>Service/Customer Support</h4>

                <p>
                  By using the Website/Services, You, hereby consent to the
                  collection and use of your contact information by the Company
                  for service/customer support related activities. Despite your
                  DND (do not disturb) registration, You, shall have the option
                  to provide your consent to the Company for being contacted by
                  Company’s customer support department and for receiving
                  service/transactional/customer support related communications
                  through calls, emails, SMS, etc.
                </p>
                <p>
                  You further agree that despite your DND registration, Company
                  shall have the option of contacting you through calls, emails,
                  SMS, etc, in cases where the Company is unable to resolve
                  customer issues/queries during an inbound call or otherwise.
                </p>
              </div>
              <div className="terms_block">
                <h4>
                  User's rights in relation to their personal information
                  collected by the Company
                </h4>

                <p>
                  All the information provided to the Company by a User,
                  including Sensitive Personal Data or Information, is
                  voluntary. User has the right to withdraw his/her/its consent
                  at any time, in accordance with the terms of this Privacy
                  Policy and the Terms of Use, but please note that withdrawal
                  of consent will not be retroactive.
                </p>
                <p>
                  Users can access, modify, correct and delete the Personal
                  Information provided by them which has been voluntarily given
                  by the User and collected by the Company in accordance with
                  this Privacy Policy and Terms of Use. However, if the User
                  updates his/her information, the Company may keep a copy of
                  the information which User originally provided to the Company
                  in its archives for User documented herein. In case the User
                  seeks to update or correct, his/her Personal Information, the
                  User may exercise these rights by emailing the Company at
                  <a href="mailto:help.rista@dotpe.in">
                    {" "}
                    help.rista@dotpe.in{" "}
                  </a>{" "}
                  and communicate the change(s) for updating the Company’s
                  records.
                </p>
                <p>
                  In case the User does not provide his/her information or
                  consent for usage of Personal Information or subsequently
                  withdraws his/her consent for usage of the Personal
                  Information so collected, the Company reserves the right to
                  discontinue the services for which the said information was
                  sought.
                </p>
              </div>
              <div className="terms_block">
                <h4>Use of the Platform</h4>

                <p>
                  You agree while using Services and Website, You shall not
                  host, display, upload, modify, publish, transmit update or
                  share any information which:
                </p>

                <ul>
                  <li>
                    belongs to another person and to which the user does not
                    have any right;
                  </li>
                  <li>
                    is obscene, pornographic, paedophilic, invasive of another’s
                    privacy including bodily privacy, insulting or harassing on
                    the basis of gender, racially or ethnically objectionable,
                    relating or encouraging money laundering or gambling, or
                    promoting enmity between different groups on the grounds of
                    religion or caste with the intent to incite violence;
                  </li>
                  <li>is harmful to child;</li>
                  <li>
                    infringes any patent, trademark, copyright or other
                    proprietary rights;
                  </li>
                  <li>
                    deceives or misleads the addressee about the origin of the
                    message or knowingly and intentionally communicates any
                    misinformation or information which is patently false and
                    untrue or misleading in nature;
                  </li>
                  <li>impersonates another person;</li>
                  <li>
                    threatens the unity, integrity, defence, security or
                    sovereignty of India, friendly relations with foreign
                    States, or public order, or causes incitement to the
                    commission of any cognisable offence, or prevents
                    investigation of any offence, or is insulting other nation;
                  </li>
                  <li>
                    contains software virus or any other computer code, file or
                    program designed to interrupt, destroy or limit the
                    functionality of any computer resource;
                  </li>
                  <li>violates any law for the time being in force.</li>
                </ul>
              </div>
              <div className="terms_block">
                <h4>Changes to the Privacy Policy</h4>

                <p>
                  We may update this Privacy Policy from time to time and may
                  notify you regarding the Policy as well as any such changes
                  periodically and at least once a year. However, you are
                  advised to review this page/policy periodically for any
                  changes. Your continued usage of the Services shall constitute
                  your acceptance of the amended/updated Privacy Policy.
                </p>

                <h4>Complaints and Grievance Redressal</h4>

                <p>
                  Any complaints, abuse or concerns with regards to content and
                  or comment or breach of these terms/Privacy Policy shall be
                  immediately informed to the designated Grievance Officer as
                  mentioned below in writing or through email:
                </p>

                <p>Shailaz Nag</p>

                <p>
                  <strong>Dotpe Private Limited </strong>
                  8th Floor, Tower-D, UNITECH CYBER PARK, Durga Colony, Sector
                  39, Gurugram, Haryana 122003
                </p>

                <p>
                  <strong>Email id:</strong>{" "}
                  <a href="mailto:help.rista@dotpe.in">help.rista@dotpe.in </a>
                  <br />
                  <strong> Phone:</strong>{" "}
                  <a href="tel:8287120030">8287120030</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
