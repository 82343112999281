import React from "react";
import successcheck3 from "../../images/brand-1.png";
import successcheck4 from "../../images/brand-2.png";
import successcheck5 from "../../images/brand-3.png";
import successcheck6 from "../../images/brand-4.png";
import successcheck7 from "../../images/brand-5.png";
import successcheck8 from "../../images/brand-6.png";

import successcheck9 from "../../images/brand-7.png";
import successcheck10 from "../../images/brand-8.png";
import successcheck11 from "../../images/brand-9.png";
import successcheck12 from "../../images/brand-10.png";
import successcheck13 from "../../images/brand-11.png";
import successcheck14 from "../../images/brand-12.png";

import successcheck15 from "../../images/brand-13.png";
import successcheck16 from "../../images/brand-14.png";
import successcheck17 from "../../images/brand-15.png";
import successcheck18 from "../../images/brand-16.png";
import successcheck19 from "../../images/brand-17.png";
import successcheck20 from "../../images/brand-18.png";
import successcheck21 from "../../images/brand-19.png";

import successcheck22 from "../../images/brand-20.png";
import successcheck23 from "../../images/brand-21.png";
import successcheck24 from "../../images/brand-22.png";

import "../../Ticker.css"; // Ensure this CSS file is created as shown next

const companies = [
  {
    name: "Coinbase",
    logo: successcheck3,
  },
  { name: "Wise", logo: successcheck4 },
  {
    name: "Headspace",
    logo: successcheck5,
  },
  { name: "Airbnb", logo: successcheck6 },
  { name: "Uber", logo: successcheck7 },
  { name: "Nike", logo: successcheck8 },
];

const Ticker = ({ direction }) => {
  const animationClass = direction === "left" ? "scroll-left" : "scroll-right";

  return (
    <div className={`ticker-container`}>
      <div className={`ticker ${animationClass}`}>
        <div className="ticker-content">
          {companies.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <img src={company.logo} alt={`${company.name} logo`} />
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const companies1 = [
  {
    name: "Coinbase",
    logo: successcheck9,
  },
  { name: "Wise", logo: successcheck10 },
  {
    name: "Headspace",
    logo: successcheck11,
  },
  { name: "Airbnb", logo: successcheck12 },
  { name: "Uber", logo: successcheck13 },
  { name: "Nike", logo: successcheck14 },
];

const Ticker1 = ({ direction }) => {
  const animationClass = direction === "left" ? "scroll-left" : "scroll-right";

  return (
    <div className={`ticker-container`}>
      <div className={`ticker ${animationClass}`}>
        <div className="ticker-content">
          {companies1.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies1.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies1.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies1.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <img src={company.logo} alt={`${company.name} logo`} />
              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};
const companies2 = [
  {
    name: "Coinbase",
    logo: successcheck13,
  },
  { name: "Wise", logo: successcheck14 },
  {
    name: "Headspace",
    logo: successcheck15,
  },
  { name: "Airbnb", logo: successcheck16 },
  { name: "Uber", logo: successcheck17 },
  { name: "Nike", logo: successcheck18 },
  { name: "Nike", logo: successcheck19 },
  { name: "Nike", logo: successcheck20 },
  { name: "Nike", logo: successcheck21 },
];
const Ticker2 = ({ direction }) => {
  const animationClass = direction === "left" ? "scroll-left" : "scroll-right";

  return (
    <div className={`ticker-container`}>
      <div className={`ticker ${animationClass}`}>
        <div className="ticker-content">
          {companies2.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies2.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies2.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies2.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <img src={company.logo} alt={`${company.name} logo`} />
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies2.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const companies3 = [
  {
    name: "Coinbase",
    logo: successcheck22,
  },
  { name: "Wise", logo: successcheck23 },
  {
    name: "Headspace",
    logo: successcheck24,
  },
];
const Ticker3 = ({ direction }) => {
  const animationClass = direction === "left" ? "scroll-left" : "scroll-right";

  return (
    <div className={`ticker-container`}>
      <div className={`ticker ${animationClass}`}>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <img src={company.logo} alt={`${company.name} logo`} />
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
function SeamlessLogos() {
  return (
    <div className="brand-logo-slide">
        <Ticker direction="left" />
        <Ticker1 direction="right" />
        <Ticker2 direction="left" />
        <Ticker3 direction="right" />
    </div>
  );
}

export default SeamlessLogos;
