import { useEffect, useRef, useState } from 'react';
import Footer from '../common/Footer';
import Header from '../common/Header';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink, useLocation } from "react-router-dom";
import contacticon from '../../images/contact_icon.svg';
import { Helmet } from 'react-helmet-async';

const ContactUs = () => {
  const location = useLocation();
  const [hasError, setHasError] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    countryCode: '+91',
    mobileNumber: '',
    query: '',
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    mobileNumber: '',
    query: '',
  });
  const inputName = useRef(null);
  const inputNumber = useRef(null);
  const inputEmail = useRef(null);
  const validateSecondForm = () => {
    const errors = {};
    let formIsValid = true;
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberRegex = /^\d+$/;

    if (!formData.name || !formData.name.trim()) {
      formIsValid = false;
      errors.name = 'Name is required';
    } else if (formData.name.trim().length < 3) {
      formIsValid = false;
      errors.name = 'Name should have at least 3 characters';
    } else if (formData.name.trim().length > 30) {
      formIsValid = false;
      errors.name = 'Name should have at max 30 characters';
    } else if (!nameRegex.test(formData.name.trim())) {
      formIsValid = false;
      errors.name = 'Name should contain only letters and spaces';
    }

    if (!formData.mobileNumber || !formData.mobileNumber.trim()) {
      formIsValid = false;
      errors.mobileNumber = 'Mobile Number is required';
    } else if (!numberRegex.test(formData.mobileNumber)) {
      formIsValid = false;
      errors.mobileNumber = 'Invalid mobile number';
    } else if (formData.mobileNumber.trim().length !== 10) {
      formIsValid = false;
      errors.mobileNumber = 'Mobile number must be exactly 10 digits';
    } else {
      // Ensure mobile number starts with 6, 7, 8, or 9
      const firstDigit = formData.mobileNumber.trim().charAt(0);
      if (!['6', '7', '8', '9'].includes(firstDigit)) {
        formIsValid = false;
        errors.mobileNumber = 'Mobile number must start with 6, 7, 8, or 9';
      }
    }
    setFormErrors(errors);
    setHasError(!formIsValid);

    return formIsValid;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'name') {
      // Ensure the name does not start with a blank character
      if (value === '' || /^[^\s]/.test(value)) {
        newValue = value;
      } else {
        newValue = value.trimStart();
      }
    } else if (name === 'mobileNumber') {
      // Replace all non-numeric characters
      newValue = value.replace(/\D/g, '');

      // Limit the mobile number to 10 digits
      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };
  const resetForms = () => {
    setFormData({
      name: '',
      mobileNumber: '',
      query: '',
    });
  };
  const [contactUsMessage, setContactUsMessage] = useState(false);
  const saveDetails = async () => {
    if (!validateSecondForm()) return;
    // console.log('form filled');
    resetForms();
    // navigate('/thank-you');
    // return;
    var apiUrl =
      process.env.REACT_APP_FORM_ONE;
    const combinedData = {
      name: formData.name,
      phone: formData.mobileNumber,
      message: formData.query,
      source: location.pathname,
    };
    console.log(combinedData);

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(combinedData),
      });

      const data = await response.json();
      console.log(data.status);
      if (data.status) {
        setContactUsMessage(true);
      }
      setTimeout(() => {
        setContactUsMessage(false);
      }, 5000);
      // setResponse(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <div>
      <Helmet>
        <title>
        Contact Us | Rista POS
        </title>
        <meta
          name="description"
          content=" Best restaurant POS system to manage your restaurant billing, Inventory, Online Orders, KOT, Menu, Table Reservation & much more."
        />
        <meta
          name="keywords"
          content="Restaurant POS Software, Restaurant Billing Software, Restaurant Management Software, Restaurant Billing"
        />
        <meta name="author" content="Rista Apps" />
        <meta
          property="og:description"
          content=" Best restaurant POS system to manage your restaurant billing, Inventory, Online Orders, KOT, Menu, Table Reservation & much more."
        />
        <meta
          property="og:image"
          content={require("../../images/logo-w.png")}
        />
      </Helmet>
      <Header />

      <section className="aboutmain contactmain">
        <div className="container">
          <div className="about_head contact_head">
            <h5>Contact Us</h5>
            <h2>Let’s Build Something Awesome Together!</h2>
            <p>
              Reach out if you’re curious about features or need to schedule a
              demo – we’re ready to answer all of your questions.
            </p>
          </div>
          {!contactUsMessage && (
            <div className="conatct_form">
              <div className="busi_loan_form">
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="name"
                      onChange={handleChange}
                      value={formData.name}
                    />
                    {formErrors.name && (
                      <span className="error">{formErrors.name}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="mobilenum">
                      <span>+91</span>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile Number"
                        name="mobileNumber"
                        maxLength={10}
                        onChange={handleChange}
                        value={formData.mobileNumber}
                      />
                      {formErrors.mobileNumber && (
                        <span className="error">{formErrors.mobileNumber}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control form_textarea"
                      placeholder="Enter your query (optional)"
                      name="query"
                      onChange={handleChange}
                      value={formData.query}
                    ></textarea>
                  </div>

                  <div className="form_click">
                    By clicking submit I hereby provide my consent to receive
                    emails, phone calls, messages and other communications from
                    Dotpe to understand about Dotpe's services, offerings,
                    promotions and other related information.
                    {/* <NavLink className='navbar-brand' to='/'>
                      Read More
                    </NavLink> */}
                  </div>

                  <div className="formlead_btn">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        if (validateSecondForm()) {
                          saveDetails();
                        }
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
        {contactUsMessage && (
          <div className="container contctt_con">
            <div className="contact_thakss">
              <p>
                {" "}
                <img src={contacticon} alt="Logo" />
                Thank you for your response! We will get back to you shortly.
              </p>
              <div className="contact_infosin">
                <p>
                  Download Rista for windows & try it out for free.{" "}
                  <NavLink
                    className="contactbtns"
                    target="_blank"
                    to="https://ristaapps.com/downloads/RistaSalesInstaller.exe"
                  >
                    Download Rista
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        )}
      </section>

      <section className="sec-2 aboutsec2 aboutseclast contactseclast">
        <div className="container">
          <div className="owl-slider">
            <OwlCarousel
              items={20}
              className="owl-theme"
              loop
              nav={false}
              margin={16}
              responsive={{
                0: { items: 5 }, // Set 1 item for small screens (width < 600px)
                600: { items: 8 }, // Set 2 items for medium screens (600px <= width < 960px)
                1025: { items: 10 },
                1299: { items: 15 }, // Set 4 items for larger screens (width >= 960px)
              }}
            >
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/1.webp")}
                    alt="Barista logo, Customer of Rista restaurant point of sale system"
                    title="Barista logo, Customer of Rista restaurant point of sale system"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/2.webp")}
                    alt="Social Logo, Client of Rista Restaurant Billing Software"
                    title="Social Logo, Client of Rista Restaurant Billing Software"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/3.webp")}
                    alt="Blue Tokia Logo, Client of Rista Cafe Billing Software"
                    title="Blue Tokia Logo, Client of Rista Cafe Billing Software"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/4.webp")}
                    alt="The Big Chill Cafe Logo, customer of rista billing software"
                    title="The Big Chill Cafe Logo, customer of rista billing software"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/5.webp")}
                    alt="Haldiram's logo, customer of rista restaurant pos"
                    title="Haldiram's logo, customer of rista restaurant pos"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/6.webp")}
                    alt="wow momo logo, customer of rista restaurant billing pos"
                    title="wow momo logo, customer of rista restaurant billing pos"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/7.webp")}
                    alt="Biryani Blues Logo, Client utilising Rista Restaurant POS"
                    title="Biryani Blues Logo, Client utilising Rista Restaurant POS"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/8.webp")}
                    alt="Frozen Bottle Logo, User of Rista Restaurant Billing Software"
                    title="Frozen Bottle Logo, User of Rista Restaurant Billing Software"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/9.webp")}
                    alt="belgian fries"
                    title="belgian fries"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/10.webp")}
                    alt="Truffles"
                    title="Truffles"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/11.webp")}
                    alt="Charcoal Eats"
                    title="Charcoal Eats"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/12.webp")}
                    alt="Pizza Wings"
                    title="Pizza Wings"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/13.webp")}
                    alt="Tibbs Frankie"
                    title="Tibbs Frankie"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/14.webp")}
                    alt="Nirula's"
                    title="Nirula's"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/15.webp")}
                    alt="The Brooklyn Creamery"
                    title="The Brooklyn Creamery"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/16.webp")}
                    alt="Kanti Sweets"
                    title="Kanti Sweets"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/17.webp")}
                    alt="Zomoz"
                    title="Zomoz"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/18.webp")}
                    alt="Meghana Foods"
                    title="Meghana Foods"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/19.webp")}
                    alt="Nic"
                    title="Nic"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/20.webp")}
                    alt="Giani Ice Cream"
                    title="Giani Ice Cream"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/21.webp")}
                    alt="Smoor"
                    title="Smoor"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/22.webp")}
                    alt="Ribbons Balloons"
                    title="Ribbons Balloons"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/23.webp")}
                    alt="Absolute Barbeeues"
                    title="Absolute Barbeeues"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/24.webp")}
                    alt="Zepto"
                    title="Zepto"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/25.webp")}
                    alt="Burgrill"
                    title="Burgrill"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/26.webp")}
                    alt="Zing"
                    title="Zing"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/27.webp")}
                    alt="Eatfit"
                    title="Eatfit"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/28.webp")}
                    alt="Biryani Blues Logo, Client utilising Rista Restaurant POS"
                    title="Biryani Blues Logo, Client utilising Rista Restaurant POS"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/29.webp")}
                    alt="Indigo"
                    title="Indigo"
                  ></img>
                </div>
              </div>
              <div className="item">
                <div className="restaurants-img">
                  <img
                    src={require("../../images/Brand-Logos-webp/30.webp")}
                    alt="Indigo"
                    title="Indigo"
                  ></img>
                </div>
              </div>
            </OwlCarousel>
          </div>

          <p className="para hmpara_btm">
            Thousands of restaurants worldwide trust RISTA to power their
            success.  <img src={require("../../images/till-arrow.png")}></img>
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ContactUs;
