import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";


const TermsConditions = () => {
  return (
    <div>
      <Header />
      <section className="temrms_main_infos">
        <div className="container">
          <div className="terms_infos">
            <div className="auxilary_head">
              <h2>Terms & Conditions</h2>
              <p>
                The portals ristaapps.com and the corresponding mobile
                application (collectively, “Platform” or "Application" governs
                the terms and conditions for the use of the Rista Apps which is
                managed and operated by Dotpe Private Limited (“Dotpe” or “Us”
                “We” or “Our”).
              </p>
            </div>
            <div className="terms_content">
              <div className="terms_block">
                <h4>Introduction</h4>

                <p>
                  These Terms of services are intended to make you aware of your
                  legal rights and responsibilities with respect to your access
                  to and use the Platform.
                </p>
                <p>
                  By downloading or otherwise accessing the Platform, you agree
                  to be bound by the following terms and conditions
                  (&ldquo;Terms &ldquo;), and privacy policy. If you have any
                  queries about the Platform or these Terms, you can contact Us
                  by any of the means as set out in these Terms. If you do not
                  agree with these Terms, you should stop using the Platform
                  immediately.
                </p>
              </div>
              <div className="terms_block">
                <h4>Scope of Service:</h4>

                <p>
                  The Platform provided by Dotpe shall be inclusive of any and
                  all regular updates, upgrades, cloud-based services, support
                  services, applications, or documentations etc. Dotpe shall not
                  be liable for any loss or damage arising from unauthorized use
                  of your account provided any such act and/or omission is not
                  attributable to Dotpe (wholly or partially).
                </p>
              </div>
              <div className="terms_block">
                <h4>License Fees:</h4>

                <p>
                  <strong>
                    The Application is offered for a recurring License Fees (as
                    defined hereunder) on a software-as-a-service (SaaS) basis.
                    License Fees shall include the following:
                  </strong>
                </p>

                <ul>
                  <li>
                    Back-end IT infrastructure on cloud, including a cluster of
                    servers and databases.
                  </li>
                  <li>
                    High-end cloud infrastructure that is always available and
                    highly scalable.
                  </li>
                  <li>Software maintenance and automatic upgrades.</li>
                  <li>
                    Technical support via online, email, or phone. Our customer
                    care support services are available at +91-82871-20030 from
                    8 AM to 12 AM every day, merchant agrees that timings
                    mentioned, and cer care support number mentioned in this
                    paragraph for customer care support services are ject to
                    change in sole discretion of Dotpe.
                  </li>
                  <li>
                    Inteve Analytics for consolidated (multiple locations) and
                    cumulative (multiple timeline) data for sales, items,
                    payments, inventory, etc.
                  </li>
                </ul>
              </div>
              <div className="terms_block">
                <h4>Changes to Application:</h4>

                <p>
                  You agree that Application may include updates, cloud-based
                  services, support services, applications or documentations,
                  each of which is subject to the Terms, as applicable. You
                  agree that Dotpe reserves the right to add, change, or
                  discontinue any component of the Application at ant time
                  without prior notice, provided however, that no such changes
                  shall materially increase your obligations or decrease your
                  entitlements.
                </p>
              </div>
              <div className="terms_block">
                <h4>Account Security:</h4>

                <p>
                  You agree that you will be responsible for all actions under
                  your Dotpe account credentials, regardless of whether such
                  actions are taken by your customers, employees, agent, members
                  etc. Dotpe is not liable for any loss or damage arising from
                  any unauthorized use of your account on account of you and
                  including but not limited to your employees, users, customers,
                  etc. Notwithstanding anything under the Terms, Dotpe shall not
                  be held liable for any loss or damage arising from any
                  unauthorized use of your account by your employees, customers,
                  agents, users, third parties etc.
                </p>
              </div>
              <div className="terms_block">
                <h4>Grant of License:</h4>
                <p>Dotpe hereby grants license to you for use of Platform:</p>
                <p>
                  During the term, Dotpe grants you a limited, non-exclusive,
                  non-transferable, non-sublicensable, revocable license to use
                  the Platform on the terms as set forth in these Terms. You
                  agrees that all rights, title and interest in and to all the
                  intellectual property rights in the Platform, and all
                  modifications, extensions and other derivative works of
                  Platform provided or developed by Dotpe are owned exclusively
                  by Dotpe or its licensors.
                </p>
                <p>
                  It is hereby agreed that all rights not granted to you under
                  the Terms are reserved by Dotpe.
                </p>
                <p>
                  You hereby grant Dotpe a royalty-free, limited, non-exclusive
                  right, authorization and license to use the name, logos,
                  trademarks, copyrights and related intellectual property of
                  yours, your users, or your licensors, solely as necessary to
                  fulfil the purposes contemplated in the Terms only.
                </p>
              </div>
              <div className="terms_block">
                <h4>Data Security:</h4>
                <p>
                  You understand and agrees that Dotpe has no control over the
                  content of the data processed by Dotpe on your behalf. You are
                  responsible for taking your own steps to maintain appropriate
                  security, protection and back-up of your data and software,
                  including the use of appropriate back-up and archiving. Dotpe
                  shall continue to perform scheduled backup of the Application
                  database, so that the latest backup can be restored for
                  service continuity in case of any glitch to the Application.
                  You remain responsible for properly handling and processing
                  notices regarding personal data of your clients. You
                  understand and agrees that Dotpe is not responsible for any
                  loss or corruption of customer data or other software.
                </p>
              </div>
              <div className="terms_block">
                <h4>Payment:</h4>
                <p>
                  You agree to purchase/renew the Platform online prior to use.
                </p>
                <h4>Termination:</h4>
                <p>
                  You agree that following termination of your account and/or
                  use of Application, Dotpe may immediately deactivate your
                  account and delete your data. You further agree that Dotpe
                  shall not be liable to you nor to any third party for any
                  termination of your access to Application or deletion of your
                  data.
                </p>
              </div>
              <div className="terms_block">
                <h4>Disclaimer:</h4>
                <p>
                  <strong>You acknowledge that:</strong>
                </p>
                <ul>
                  <li>
                    Dotpe cannot guarantee the results generated through
                    Application, or that Application will be continuously
                    available for use without interruption, and
                  </li>
                  <li>
                    Application is provided &quot;as-is&quot;, on an &ldquo;as
                    available&rdquo; basis without any representation, warranty
                    or condition of any kind, and Dotpe hereby disclaims all
                    conditions, representations and all warranties whether
                    express or implied, arising by law or otherwise with respect
                    to Application, including, but not limited to, any (i)
                    implied warranty of merchantability or fitness for a
                    particular purpose, (ii) implied warranty arising from
                    course of performance, course of dealing, or usage of trade,
                    or (iii) statutory remedy.
                  </li>
                </ul>
              </div>
              <div className="terms_block">
                <h4>General</h4>

                <ul>
                  <li>
                    These Terms (as amended from time to time) constitute the
                    entire agreement between you and Dotpe concerning your use
                    of the Application.
                  </li>
                  <li>
                    Dotpe reserves the right to update these Terms from time to
                    time. You are responsible for regularly reviewing these
                    Terms so that you are aware of any changes to them, and you
                    will be bound by the new policy upon your continued use of
                    the Application. No other variation to these Terms shall be
                    effective unless in writing and signed by an authorized
                    representative on behalf of Dotpe.
                  </li>
                  <li>
                    We will send you information relating to your account (e.g.
                    payment authorizations, invoices, changes in password or
                    payment Method, confirmation messages, notices) in
                    electronic form only.
                  </li>
                  <li>
                    These Terms shall be governed by and construed in accordance
                    with Indian laws and you agree to submit to the exclusive
                    jurisdiction of Gurugram, Haryana, India.
                  </li>
                  <li>
                    If any provision(s) of these Terms is held by a court of
                    competent jurisdiction to be invalid or unenforceable, then
                    such provision(s) shall be construed, as nearly as possible,
                    to reflect the intentions of the parties (as reflected in
                    the provision(s)) and all other provisions shall remain in
                    full force and effect.
                  </li>
                  <li>
                    Dotpe&rsquo;s failure to exercise or enforce any right or
                    provision of these Terms shall not constitute a waiver of
                    such right or provision unless acknowledged and agreed to by
                    Dotpe in writing.
                  </li>
                </ul>

                <p>
                  By using the Application, the Merchant agrees to abide by
                  these Terms.
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
  


};

export default TermsConditions;
